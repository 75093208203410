<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      :ok-disabled="isDisableBtn"
      header-close-content=""
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- Name Input -->
          <b-col cols="6">
            <b-form-group id="input-name">
              <label for="nameInput">Name: <span class="text-danger">*</span></label>
              <b-form-input
                trim
                id="nameInput"
                name="name"
                v-model="editedItem.name"
                type="text"
                aria-describedby="input-name-feedback"
                placeholder="Product name"
                data-vv-as="name"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-validate="{ required: true, min: 3, max: 127 }"
              ></b-form-input>
              <span v-show="errors.has('name')" class="is-danger-text position-relative">{{
                errors.first('name')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- select Game -->
          <b-col cols="6">
            <b-form-group id="input-game" label="Game:" label-for="Game">
              <b-form-select
                v-model="selectedGame"
                :options="allGameAdsList"
                @change="updateGame"
                name="game"
                data-vv-as="Game"
                :class="{ input: true, 'is-danger': errors.has('game') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Game--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">--Please select Game--</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('game')" class="is-danger-text position-relative">{{
                errors.first('game')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Handler -->
          <b-col v-if="isCreated" cols="12">
            <b-form-group id="input-handler">
              <label for="handlerInput">Handler:</label>
              <b-form-input
                trim
                id="handlerInput"
                name="handler"
                v-model="editedItem.handler"
                type="text"
                aria-describedby="input-handler"
                placeholder="Handler..."
                data-vv-as="handler"
                :class="{ input: true, 'is-danger': errors.has('handler') }"
                v-validate="{ min: 1, max: 127 }"
              ></b-form-input>
              <span v-show="errors.has('handler')" class="is-danger-text position-relative">{{
                errors.first('handler')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- status -->
          <b-col cols="4">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select
                v-model="filter_status"
                :options="statusList"
                name="Status"
                data-vv-as="Status"
                :class="{ input: true, 'is-danger': errors.has('Status') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Status--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">--Please select Status--</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('Status')" class="is-danger-text position-relative">{{
                errors.first('Status')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- Price -->
          <b-col cols="4">
            <b-form-group id="input-price">
              <label for="priceImput">Price:</label>
              <b-form-input
                trim
                id="priceImput"
                name="price"
                v-model="editedItem.price"
                type="number"
                min="0"
                aria-describedby="input-price"
                placeholder="Price..."
                data-vv-as="price"
                :class="{ input: true, 'is-danger': errors.has('price') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('price')" class="is-danger-text position-relative">{{
                errors.first('price')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- Slot Count -->
          <b-col cols="4">
            <b-form-group id="input-slot-count">
              <label for="slotCountInput">Slot Count:</label>
              <b-form-input
                trim
                id="slotCountInput"
                name="slotCount"
                v-model="editedItem.slot_count"
                type="number"
                min="0"
                aria-describedby="input-slot-count"
                placeholder="Slot count..."
                data-vv-as="name"
                :class="{ input: true, 'is-danger': errors.has('slotCount') }"
                v-validate="{ required: true, numeric: true }"
              ></b-form-input>
              <span v-show="errors.has('slotCount')" class="is-danger-text position-relative">{{
                errors.first('slotCount')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- Regex -->
          <b-col cols="12">
            <b-form-group id="input-regex" label="Regex:" label-for="regexInput">
              <b-form-input
                trim
                id="regexInput"
                v-model="editedItem.regex"
                type="text"
                placeholder="Regex"
                name="regex"
                data-vv-as="regex"
                :class="{ input: true, 'is-danger': errors.has('regex') }"
                v-validate="{ min: 1, max: 16 }"
              ></b-form-input>
              <span v-show="errors.has('regex')" class="is-danger-text position-relative">{{
                errors.first('regex')
              }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Product List</h4>
      </b-col>
      <!-- FilterName -->
      <b-col cols="4">
        <b-form-group id="input-name-filter" label="Product" label-for="name-filter">
          <b-form-input
            id="name-filter"
            v-model="filter_name"
            type="text"
            placeholder="Search articles ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- FilterCategori -->
      <b-col cols="2">
        <b-form-group id="input-screen-filter" label="Game" label-for="screen-filter">
          <b-form-select id="screen-filter" v-model="filter_game" :options="allGameAdsList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- Filter Status -->
      <b-col cols="2">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="4" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
    >
      <!-- <template v-slot:cell(game)="item">
        {{ item.value.name }}
      </template> -->
      <template v-slot:cell(price)="item">
        {{ formatPrice(item.item.price) }}
      </template>
      <template v-slot:cell(status)="item" :v-if="item.value == 1">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="danger">Inactive</b-badge>
        <b-badge class="mr-1" v-else variant="warning">Pending</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success mr-3" @click="editItem(item.item)">mdi-pencil</v-icon>
        <v-icon class="text-danger" @click="deleteItem(item.item)">mdi-delete</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
export default {
  name: 'Product Reward',
  components: {},
  data() {
    return {
      isCreated: true,
      search: '',
      isBusy: false,
      filter_name: '',
      filter_game: null,
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Name', key: 'name' },
        { text: 'Game', key: 'game' },
        { text: 'Handler', key: 'handler' },
        { text: 'Price', key: 'price' },
        { text: 'Regex', key: 'regex' },
        { text: 'Slot Count', key: 'slot_count' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      allGameAdsList: [],
      selectedGame: null,
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        name: '',
        game_id: '',
        game: '',
        price: '',
        regex: '',
        slot_count: '',
        status: '',
        handler: ''
      },
      dialog: false,
      editedIndex: -1
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        this.errors.has('name') ||
        !this.editedItem.name ||
        !this.selectedGame ||
        this.errors.has('Status') ||
        this.filter_status === null ||
        !this.editedItem.price ||
        !this.editedItem.slot_count
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      // {
      //   title: "Product",
      //   route: "product-list"
      // },
      { title: 'Product List' }
    ]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsProduct = ApiService.get(
        'products',
        `?name=${this.filter_name}&game_id=${this.filter_game || ''}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      );
      const allGame = ApiService.get('allgame');
      axios
        .all([adsProduct, allGame])
        .then(
          axios.spread((...response) => {
            const adsProductRes = response[0];
            const allGameRes = response[1];
            this.items = adsProductRes.data.data.productList.map((ads) => {
              return {
                ...ads
              };
            });
            this.rows = adsProductRes.data.data.total;
            this.allGameAdsList = allGameRes.data.data;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    addItem: function () {
      this.isCreated = true;
      this.dialog = true;
      this.modalTitle = 'Add Product';
    },
    editItem: function (item) {
      this.isCreated = false;
      this.dialog = true;
      this.modalTitle = 'Update Product';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
      this.selectedGame = item.game_id;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        ApiService.delete(`products/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Product deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          });
      }
    },
    close() {
      this.dialog = false;
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = {
        id: '',
        name: '',
        game_id: '',
        game: '',
        price: '',
        regex: '',
        slot_count: '',
        hander: ''
      };
      this.editedIndex = -1;
      this.selectedGame = null;
      this.filter_status = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Validate required fields
      // Get new brand/cate/vendor name for data-table
      this.editedItem.game_id = this.selectedGame;
      let productData = {
        name: this.editedItem.name,
        game_id: this.editedItem.game_id,
        price: Math.floor(parseFloat(this.editedItem.price)),
        regex: this.editedItem.regex || undefined,
        slot_count: parseFloat(this.editedItem.slot_count),
        status: this.filter_status,
        handler: this.editedItem.handler || undefined
      };
      if (this.editedIndex > -1) {
        // Update product
        axios({
          method: 'PUT',
          url: `products/${this.items[this.editedIndex].id}`,
          data: productData
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              // this.editedItem.game_id = this.filter_game;
              //update game
              this.allGameAdsList.some((game) => {
                if (game.value == this.selectedGame) {
                  this.editedItem.game = game.text;
                  this.editedItem.game_id = game.value;
                  return true;
                }
              });
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Product updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast(`errors`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          });
      } else {
        // Add product
        axios({
          method: 'POST',
          url: 'products',
          data: productData,
          headers: { 'Content-Type': 'application/json' }
        })
          .then((response) => {
            if (response.data.status) {
              this.items.unshift({
                ...response.data.data
              });
              this.close();
              this.$bvToast.toast('Product added successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.errors.push(response.data.data);
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          });
      }
    },
    updateGame() {}
  }
};
</script>
